import React from "react";

import Layout from "../components/Layout";

const Feedback = () => {
  return (
    <Layout pageTitle="Feedback" metaTitle="We'd love to hear your opinion">
      <iframe
        className="google-form-feedback"
        src="https://docs.google.com/forms/d/e/1FAIpQLSd8kkNyvNGAw7YVE7h4T_GSC_X8vQkKDeaPMuBd_AsXAQ2GUg/viewform?embedded=true"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        title="Google forms: Feedback"
      >
        Cargando…
      </iframe>
    </Layout>
  );
};

export default Feedback;
